main {
  width: 70%;
  margin: auto;
  text-align: center;
}

.btn {
  width: 200px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  outline: none;
  background: black;
  color: white;
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.btn:hover {
  color: white !important;
}

.btn:focus {
  color: white !important;
}
