.container {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

form {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
}

label {
  font-weight: bold;
  color: black;
}

input {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 250px;
}

.submitBtn {
  background: black;
  color: white;
  margin-top: 10px;
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.logo {
  max-width: 250px;
  margin-bottom: 20px;
}
